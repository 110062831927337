'use client';

import middlewares from '@akinon/next/redux/middlewares';
import reducers from '@akinon/next/redux/reducers';
import {
  Action,
  AnyAction,
  configureStore,
  Store,
  ThunkAction,
  ThunkDispatch
} from '@reduxjs/toolkit';

export const makeStore = (): Store<{
  [key in keyof typeof reducers]: ReturnType<(typeof reducers)[key]>;
}> =>
  configureStore({
    reducer: {
      ...reducers
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([...middlewares])
  });

export type AppStore = ReturnType<typeof makeStore>;

export type RootState = ReturnType<AppStore['getState']>;

export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const store = makeStore();
