import { createContext } from 'react';
type ToastContextValue = {
  open: (
    message: string,
    buttonText?: string,
    url?: string,
    className?: string
  ) => void;
  close: (id: string) => void;
};
export const ToastContext = createContext<ToastContextValue | null>(null);