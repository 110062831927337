'use client';
import { useEffect, useMemo, useState } from 'react';

import { Icon } from './icon';
import { Link } from './link';
import { Button } from './button';
import { ToastContext } from './toast-context';
import { twMerge } from 'tailwind-merge';
type ToastProviderProps = {
  children: React.ReactElement;
};
type ToastType = {
  id: string;
  message: string;
  className?: string;
  icon?: string;
};
interface ToastInterface {
  message: string;
  duration?: number;
  close: () => void;
  icon?: string;
  className?: string;
}
let count = 0;
function genId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
}
export default function Toast({
  message,
  close,
  duration = 5000,
  icon,
  className
}: ToastInterface) {

  useEffect(() => {
    const timer = setTimeout(() => {
      close();
    }, duration);
    return () => clearTimeout(timer);
  }, [close, duration]);

  return (
    <>
      <div
        className={twMerge(
          'fixed z-[100] bottom-[64px] md:bottom-[96px] left-0 right-0 m-auto max-w-fit min-w-[153px] animate-slideInUp',
          className
        )}
      >
        <div className="bg-primary rounded-md py-1.5 px-4 shadow-sm flex items-center justify-center">
          {icon && <div className='bg-white w-4 h-4 rounded-full flex items-center justify-center'>
            <Icon name={icon} size={8} className="text-primary" />
          </div>}
          <p className="text-gray-50 text-sm mx-3">
            {message}
          </p>
        </div>
      </div>
    </>
  );
}
export function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<ToastType[]>([]);
  function openToast(
    message: string,
    buttonText?: string,
    url?: string,
    className?: string
  ) {
    const newToast = {
      id: genId(),
      message,
      buttonText,
      url,
      className
    };
    setToasts((prevToasts) => [...prevToasts, newToast]);
  }
  function closeToast(id: string) {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }
  const contextValue = useMemo(
    () => ({
      open: openToast,
      close: closeToast
    }),
    []
  );
  return (
    <>
      <ToastContext.Provider value={contextValue}>
        {toasts &&
          toasts.map((toast) => (
            <Toast
              key={toast.id}
              message={toast.message}
              close={() => closeToast(toast.id)}
              className={toast.className}
            />
          ))}
        {children}
      </ToastContext.Provider>
    </>
  );
}